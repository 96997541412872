<template>
  <Backoffice>
    <div class="wrapper">
      <main>
        <div class="ui fluid container backoffice-page__content">
          <div class="ui grid">
            <div class="row">
              <h1 class="sixteen column wide">Ferramentas</h1>
            </div>

            <div class="row">
              <div class="ui segment sixteen wide column">
                <div class="ui icon header">
                  <h2>Integrar assinaturas</h2>
                </div>
                <div class="ui grid">
                  <div class="five wide column">
                    <div class="ui segments">
                      <div class="ui segment">
                        <p>Assinantes</p>
                      </div>
                      <div
                        v-for="email in emailList"
                        :key="email"
                        class="ui secondary segment"
                      >
                        <div>
                          <p>{{ email }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="six wide column">
                    <div class="ui action input">
                      <input
                        type="email"
                        v-model="subscriberEmail"
                        placeholder="cliente@email.com"
                      />
                      <button
                        class="ui secondary button"
                        @click="() => addSubscriberEmail(subscriberEmail)"
                      >
                        Adicionar email
                      </button>
                    </div>
                  </div>
                  <div class="three wide column">
                    <div class="ui action">
                      <button
                        class="ui button button__primary"
                        :disabled="emailList.length < 1"
                        @click="() => integrateSubscriptions(emailList)"
                      >
                        Integrar
                      </button>
                    </div>
                  </div>
                  <div class="two wide column">
                    <button
                      class="circular ui left floated icon mini button"
                      @click="resetIntegrationTool"
                      title="Resetar"
                    >
                      <i class="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
                <div class="ui grid">
                  <div class="row">
                    <div class="column">
                      <table class="ui selectable celled table">
                        <thead>
                          <tr>
                            <th>Email</th>
                            <th>Assinaturas encontradas</th>
                            <th>Data da busca</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="row in emailsProcessed"
                            :key="`${row.email}_${row.data}`"
                          >
                            <td>
                              {{ row.email }}
                            </td>
                            <td>
                              {{ row.assinaturas }}
                            </td>
                            <td>
                              {{ row.data }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </Backoffice>
</template>

<script>
import md5 from "md5";
import _ from "lodash";
import SystemLoading from "../../../utils/SystemLoading";
import Backoffice from "../../Template/Backoffice";

export default {
  name: "Tools",
  components: {
    Backoffice
  },
  watch: {},
  data() {
    return {
      subscriberEmail: "",
      emailList: [],
      emailsProcessed: []
    };
  },
  computed: {},
  methods: {
    resetIntegrationTool() {
      this.subscriberEmail = "";
      this.emailList = [];
      this.emailsProcessed = [];
    },
    addSubscriberEmail(email = "") {
      if (!email || this.emailList.includes(email)) {
        return false;
      }

      this.emailList.push(email);

      return true;
    },
    integrateSubscriptions(emailList = []) {
      if (!emailList.length) {
        return false;
      }

      SystemLoading.show("Processando");

      this.$store
        .dispatch("Backoffice/INTEGRATE_SUBSCRIPTIONS", { emailList })
        .then(({ processed }) => {
          this.emailsProcessed = processed;
        })
        .catch(({ response }) => {
          console.error("Ocorreu um erro ao tentar integrar as assinaturas.");
        })
        .then(() => {
          this.$nextTick(() => {
            SystemLoading.close();
          });
        });
    }
  },
  beforeCreate() {},
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/globals/buttons";

h2 {
  font-family: Barlow;
  font-size: 16.5px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: 0.2px;
  text-align: right;
  color: #757575;
  margin: 0;
  padding-left: 0.5rem;
}

table {
  thead {
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  tbody {
    &.no-data {
      tr {
        cursor: default;
      }
      h2 {
        text-align: center;
      }
    }
    tr {
      transition: 200ms;
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
